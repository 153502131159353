<script setup>
import dayjs from 'dayjs';
import { cloneDeep, forEach, groupBy, isEqual, sortBy, toLower } from 'lodash-es';

import HawkHandsontable from '~/common/components/organisms/hawk-handsontable/hawk-handsontable.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import useEmitter from '~/common/composables/useEmitter';

import { exportTableAsXLSX } from '~/common/utils/table.utils.js';

import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
});

const can_configure_reports = inject('can_configure_reports');

const { $t, $services, route, common_store, auth_store, $date } = useCommonImports();

const emitter = useEmitter();

const dashboard_store = useDashboardStore();
const inventory_store = useInventoryStore();

const DEFAULT_WIDTHS = {
  name: 300,
  number: 200,
  description: 400,
  uom: 150,
  adj_date: 300,
};

const CUSTOM_FIELD_RENDERERS = {
  member: 'membersRenderer',
  members: 'membersRenderer',
  url: 'urlRenderer',
  email: 'emailRenderer',
  phone_number: 'phoneNumberRenderer',
  signature: 'signatureRenderer',
};

const prevent_watcher = ref(false);
const reports_data = ref([]);
const columns = ref([]);
const table_instance = ref(null);
const custom_fields = ref({});
const loading = ref(false);
const nested_headers = ref([]);

const get_workflow = computed(() => {
  return uid => inventory_store.workflows_map[uid] || {};
});

const asset_name = computed(() => {
  return uid => common_store.get_asset(uid)?.name || '-';
});

const is_grouping_enabled = computed(() => {
  const filters = parseFilters();
  return !!filters?.group_by?.length;
});
const columns_widths_map = computed(() => props.data.data?.properties?.columns_widths || {});
const columns_order_list = computed(() => {
  if (props.data.data?.properties?.columns_order?.length) {
    return props.data.data?.properties?.columns_order;
  }
  else if (props.data.data.type === 'transaction') {
    return ['adj_date', 'adj_number', 'adj_workflow_name', 'from_stock', 'to_stock', 'number', 'name', 'description', 'quantity'];
  }
  return [];
});

watch(() => cloneDeep(props.data), async (new_val, old_val) => {
  if (new_val && !isEqual(new_val, old_val)) {
    if (prevent_watcher.value) {
      prevent_watcher.value = false;
      return;
    }
    await getData();
  }
}, { deep: true });

function toFromStockParser(obj) {
  return inventory_store.get_location_details({
    uid: obj.uid,
    type: obj.type,
  })?.name || '';
}

function formatMemberDetails(member_uid) {
  const member_details = common_store.get_user(member_uid);
  return member_details.first_name
    ? `${member_details.first_name} ${member_details.last_name} (${member_details.email})`
    : member_details.email;
}

function getDateFilterRange(filters) {
  if (props.data.data.type === 'adjustment')
    return filters.dates;
  if (Array.isArray(filters.date))
    return filters.date;
  else if (filters.date)
    return [dayjs(filters.date).startOf('day').toISOString(), dayjs(filters.date).endOf('day').toISOString()];
  else
    return [null, null];
}

function parseFilters() {
  const rules = props.data.data?.filter_rules || [];
  const filters = {};
  rules.forEach((rule) => {
    if (Array.isArray(rule.value) || rule.field === 'dates' || rule.field === 'date')
      filters[rule.field] = rule.value;
    else
      filters[rule.field] = [rule.value];
  });
  return filters;
}

async function getData() {
  loading.value = true;
  const filters = parseFilters();
  if (props.data.data.type !== 'transaction') {
    const payload = {
      group_by:
        (props.data.data.type === 'adjustment' && filters.group_by)
          ? filters.group_by
          : [],
      type: props.data.data.type,
      filters: {
        dates: getDateFilterRange(filters),
        workflow: filters?.workflow,
      },
      filter_rules: props.data.data?.filter_rules,
    };

    const res = await inventory_store.$services.inventory_reports.post({
      body: payload,
      query: {
        asset: route.params.asset_id,
      },
      // signal: filters$.value?.signal,
    });
    reports_data.value = res.data.result;
    columns.value = parse_columns();
    reports_data.value = sortBy(parse_data(res.data.result), item => toLower(item.name));
  }
  else {
    const query = {
      'include[]': ['adjustment.*', 'item.uom.*'],
      'report': true,
      'asset': route.params.asset_id,
      'startDate': filters?.dates?.[0] ? dayjs(filters?.dates?.[0]).toISOString() : undefined,
      'endDate': filters?.dates?.[1] ? dayjs(filters?.dates?.[1]).toISOString() : undefined,
      ...(filters?.workflow?.length && { workflow: filters.workflow }),
    };

    const res = await inventory_store.$services.inventory_reports.get_adjustment_lines({
      query: {
        ...query,
      },
    });
    reports_data.value = res.data.results;
    columns.value = parse_columns();
    reports_data.value = sortBy(parse_data(res.data.result), item => toLower(item.name));
  }
  loading.value = false;
}

function get_custom_field_name(key) {
  const filters = parseFilters();
  const uid = filters?.group_by?.[0]?.split('.')[1];
  const field = inventory_store.get_custom_field(uid);

  if (field.type === 'date')
    return dayjs(key).format('YYYY-MM-DD');

  if (field.type === 'member') {
    const user = common_store.users_map[key] || {};
    return `${user.first_name} ${user.last_name}`;
  }

  if (field.type === 'members') {
    return key
      .split(',')
      .map(uid => common_store.users_map[uid] || {})
      .map(user => `${user.first_name} ${user.last_name}`)
      .join(', ');
  }

  return key === 'null' ? 'NA' : key;
}

function parse_columns() {
  nested_headers.value = [];
  const filters = parseFilters();
  const columns = [
    {
      data: 'number',
      readOnly: true,
      text: $t('Item number'),
    },
    {
      data: 'name',
      readOnly: true,
      text: $t('Item name'),
    },
    ...(props.data.data.type === 'transaction'
      ? []
      : [{
          data: 'uom',
          readOnly: true,
          text: $t('Unit of measure'),
        }]),
    {
      data: 'description',
      readOnly: true,
      text: $t('Description'),
    },
  ];
  if (props.data.data.type === 'adjustment') {
    if (filters?.group_by?.[0]) {
      let grouped_by = {};
      if (filters.group_by?.[0] === 'date') {
        grouped_by = groupBy(reports_data.value, (item) => {
          return item.date;
        });
      }
      else if (filters.group_by?.[0] === 'warehouse') {
        grouped_by = groupBy(reports_data.value, (item) => {
          return item.warehouse?.uid;
        });
      }
      else if (filters.group_by?.[0] === 'asset') {
        grouped_by = groupBy(reports_data.value, item => item.asset_uid);
      }
      else if (filters.group_by?.[0]?.startsWith('custom_field')) {
        grouped_by = groupBy(reports_data.value, item => item.custom_field);
      }

      const first_level = [{ label: '', colspan: columns.length }];
      const second_level = [...columns.map(column => ({ label: column.text, colspan: 1 }))];

      forEach(grouped_by, (g1_value, g1_key) => {
        const grouped_workflow = groupBy(g1_value, item => item.workflow.uid);
        const header_label = filters.group_by?.[0] === 'asset'
          ? asset_name.value(g1_key)
          : filters.group_by?.[0] === 'warehouse'
            ? g1_value[0][filters.group_by?.[0]]?.name
            : filters.group_by?.[0] === 'date'
              ? g1_key
              : filters.group_by?.[0]?.startsWith('custom_field')
                ? get_custom_field_name(g1_key)
                : g1_key;
        first_level.push({
          label: header_label,
          colspan: filters.group_by?.[0] === 'date' || filters.group_by?.[0] === 'warehouse' ? Object.keys(grouped_workflow).length + 1 : Object.keys(grouped_workflow).length,
        });

        Object.values(grouped_workflow).forEach((g2_value) => {
          const workflow = get_workflow.value(g2_value[0].workflow.uid);
          second_level.push({
            label: workflow.name,
            colspan: 1,
            data: `${g1_key}_${workflow.uid}`,
          });
          columns.push({
            data: `${g1_key}_${workflow.uid}`,
            text: header_label,
            readOnly: true,
          });
        });

        if (filters.group_by?.[0] === 'date' || filters.group_by?.[0] === 'warehouse') {
          second_level.push({
            label: 'Stock in hand',
            colspan: 1,
            data: `${g1_key}_stock_in_hand`,
          });
          columns.push({
            data: `${g1_key}_stock_in_hand`,
            text: 'Stock in hand',
            readOnly: true,
          });
        }

        nested_headers.value = [first_level, second_level];
      });

      return getOrderedColumnsWithWidths(columns);
    }
    else {
      const grouped_workflow = groupBy(reports_data.value, item => item.workflow?.uid);
      const workflow_columns = sortBy(Object.keys(grouped_workflow).map((key) => {
        return {
          data: key,
          text: `${grouped_workflow[key][0].workflow.name} quantity`,
          readOnly: true,
        };
      }), workflow => toLower(workflow.text));
      columns.push(...workflow_columns);
    }
  }

  if (props.data.data.type === 'to_status') {
    const grouped_statuses = groupBy(reports_data.value, item => item.status_obj?.uid);
    const status_columns = sortBy(Object.keys(grouped_statuses).map((key) => {
      return {
        data: key,
        text: `${grouped_statuses[key][0].status_obj.name}`,
        readOnly: true,
      };
    }), status => toLower(status.text));
    columns.push(...status_columns);
  }

  if (props.data.data.type === 'transaction') {
    columns.push(
      ...[
        {
          data: 'adj_date',
          text: $t('Transaction published date'),
          readOnly: true,
          renderer: (_instance, td, _row, _col, _prop, value) => {
            td.textContent = value ? $date(value, 'DD MMMM YYYY h:mm A') : '';
          },
          custom_renderer: true,
        },
        {
          data: 'adj_workflow_name',
          text: $t('Transaction type'),
          readOnly: true,
        },
        {
          data: 'adj_number',
          text: $t('Transaction number'),
          readOnly: true,
        },
        {
          data: 'from_stock',
          text: $t('From'),
          readOnly: true,
          renderer: (_instance, td, _row, _col, _prop, value) => {
            td.textContent = value ? toFromStockParser(value) : '';
          },
          custom_renderer: true,
        },
        {
          data: 'to_stock',
          text: $t('To'),
          readOnly: true,
          renderer: (_instance, td, _row, _col, _prop, value) => {
            td.textContent = value ? toFromStockParser(value) : '';
          },
          custom_renderer: true,
        },
        {
          data: 'quantity',
          text: $t('Quantity'),
          readOnly: true,
        },
      ],
    );

    custom_fields.value = reports_data.value.reduce((custom_fields, curr) => {
      return {
        ...custom_fields,
        ...curr.adj_custom_fields.reduce((fields, field) => {
          return {
            ...fields,
            [field.uid]: field,
          };
        }, {}),
      };
    }, {});

    columns.push(
      ...sortBy(Object.values(custom_fields.value).map((cf) => {
        const cf_type = inventory_store.get_custom_field(cf.uid)?.type;
        const res = {
          data: cf.uid,
          readOnly: true,
          text: inventory_store.get_custom_field(cf.uid)?.name,
          cf_type,
          ...(cf_type === 'checkboxes' && {
            renderer: (_instance, td, _row, _col, _prop, value) => {
              td.textContent = Array.isArray(value) ? value.join(', ') : (value || '');
            },
            custom_renderer: true,
          }),
          ...(cf_type === 'date' && {
            renderer: (_instance, td, _row, _col, _prop, value) => {
              td.textContent = value ? $date(value, 'DD MMMM YYYY h:mm A') : '';
            },
            custom_renderer: true,
          }),
        };
        return {
          ...(CUSTOM_FIELD_RENDERERS[res.cf_type] && { renderer: CUSTOM_FIELD_RENDERERS[res.cf_type] }),
          ...res,
        };
      }), cf => toLower(cf.text)),
    );
  }

  return getOrderedColumnsWithWidths(columns);
}

function getOrderedColumnsWithWidths(columns) {
  let columns_list = [];

  if (is_grouping_enabled.value) {
    columns_list = columns;
  }
  else {
    columns_list = [
      ...columns.filter(column => columns_order_list.value.includes(column.data)).sort((a, b) => {
        return columns_order_list.value.indexOf(a.data) - columns_order_list.value.indexOf(b.data);
      }),
      ...columns.filter(column => !columns_order_list.value.includes(column.data)),
    ];
  }

  return columns_list.map(item => ({
    ...item,
    width: columns_widths_map.value[item.data] || DEFAULT_WIDTHS[item.data] || 150,
  })).filter(x => x.text);
}

function parse_data(val) {
  const filters = parseFilters();
  if (props.data.data.type === 'to_status') {
    const grouped_data = groupBy(val, item => item.item_obj.uid);
    const data = [];
    Object.keys(grouped_data).forEach((key) => {
      const item = {
        ...grouped_data[key][0].item_obj,
        uom: grouped_data[key][0].uom_obj.symbol,
      };
      grouped_data[key].forEach((status) => {
        item[status.status_obj.uid] = status.stock_quantity;
      });

      data.push(item);
    });

    return data;
  }
  if (props.data.data.type === 'adjustment') {
    const grouped_data = groupBy(val, item => item.item.uid);

    const data = [];
    Object.keys(grouped_data).forEach((key) => {
      const item = {
        ...grouped_data[key][0].item,

        uom: grouped_data[key][0].uom.symbol,
      };
      grouped_data[key].forEach((status) => {
        const pre_key = filters.group_by?.[0]
          ? `${
            status[filters.group_by?.[0]]?.uid
            || status.asset_uid
            || status.custom_field
            || status.date
            || 'null'
          }_`
          : '';
        item[pre_key + status.workflow.uid] = status.stock_quantity;
        item[`${pre_key}stock_in_hand`] = status.stock_in_hand;
      });

      data.push(item);
    });

    return data;
  }
  if (props.data.data.type === 'transaction') {
    return reports_data.value.map((item) => {
      return {
        ...item,
        name: item.item_name,
        number: item.item_number,
        description: item.item_description,
        ...item.adj_custom_fields.reduce((res, cf) => {
          return { ...res, [cf.uid]: cf.value };
        }, {}),
        quantity: `${item.item_quantity} ${item.item_uom}`,
      };
    });
  }
}

function getCustomFieldStringfiers() {
  return Object.keys(inventory_store.custom_fields_map).reduce((acc, uid) => {
    acc[uid] = (custom_field_value) => {
      const custom_field_details = inventory_store.get_custom_field(uid);
      if (custom_field_details.type === 'checkboxes')
        return custom_field_value.join(', ');
      else if (custom_field_details.type === 'member')
        return formatMemberDetails(custom_field_value);
      else if (custom_field_details.type === 'members')
        return custom_field_value.map(member => formatMemberDetails(member)).join(' | ');
      else if (custom_field_details.type === 'date')
        return dayjs(custom_field_value).format('D MMMM, YYYY');
      else if (custom_field_details.type === 'signature')
        return $t('Signature');
      return custom_field_value;
    };
    return acc;
  }, {});
}

function onExportClicked() {
  const stringifier_map = {
    from_stock: obj => toFromStockParser(obj),
    to_stock: obj => toFromStockParser(obj),
    ...getCustomFieldStringfiers(),
  };

  exportTableAsXLSX(table_instance.value, props.data?.data?.name, true, stringifier_map);
}

function columnResized(columns_widths) {
  prevent_watcher.value = true;
  const columns_width_by_key = columns.value.reduce((acc, col, idx) => {
    acc[col.data] = { size: columns_widths[idx], id: col.data };
    return acc;
  }, {});

  dashboard_store.set_is_editing_dashboard(true);
  dashboard_store.set_table_column_widths(
    props?.id,
    columns_width_by_key,
  );
  dashboard_store.set_is_editing_dashboard(false);

  columns.value = getOrderedColumnsWithWidths(columns.value);
  if (props.id !== 'preview') {
    lazyDashboardUpdate();
  }
}

function lazyDashboardUpdate() {
  $services.dashboard.patch({
    id: dashboard_store.current_dashboard.uid,
    body: dashboard_store.current_dashboard,
  });
}

function hotSettings() {
  return {
    rowHeaders: true,
    rowHeights: 26,
    viewPortRowRenderingOffset: 100,
    viewportColumnRenderingOffset: 40,
    ...(auth_store.check_split('inventory_reports_filters') && props.id !== 'preview'
      ? { dropdownMenu: ['filter_by_condition', 'filter_by_value', 'filter_action_bar'], filters: true }
      : { dropdownMenu: false, filters: false }),
    manualColumnResize: props.id === 'preview' ? true : can_configure_reports.value,
    manualRowMove: false,
    manualColumnMove: props.id === 'preview' && !is_grouping_enabled.value,
  };
}

function afterColumnsDragged(e) {
  prevent_watcher.value = true;
  const new_column_order = e.map((header) => {
    return columns.value.find(col => col.text === header).data;
  });
  dashboard_store.set_table_column_order(new_column_order);
}

const colHeaders = function (index) {
  return columns.value[index].text;
};

onMounted(() => {
  if (props.id !== 'preview') {
    emitter.on('export-inventory-report', (_uid) => {
      onExportClicked();
    });
  }
  setTimeout(async () => {
    await getData();
  }, 100);
});

onUnmounted(() => {
  if (props.id !== 'preview')
    emitter.off('export-inventory-report');
});
</script>

<template>
  <div>
    <HawkLoader v-if="loading" container_class="m-1" />
    <div
      v-else-if="!loading"
      :class="id === 'preview' ? 'h-[calc(100vh-320px)]' : 'h-[calc(100vh-195px)]'"
    >
      <HawkHandsontable
        :apply-read-only-class="false"
        :data="reports_data"
        :columns="columns"
        :hot-settings="hotSettings()"
        :hot-table-id="id"
        :col-headers="colHeaders"
        :nested-headers="nested_headers"
        :height="id !== 'preview' ? '100%' : '450px'"
        @after-columns-resized="columnResized"
        @after-columns-dragged="afterColumnsDragged"
      />
    </div>
  </div>
</template>
